import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const initialState = {
    show: false,
    showMessage: false
}


const notificationReducer = createReducer(initialState)({
    [types.SHOW_NOTIFICATION_DOTS]: (state, action) => ({ show: action.payload }),
});
const messageNotificationReducer = createReducer(initialState)({
    [types.SHOW_MESSAGE_DOTS]: (state, action) => ({ showMessage: action.payload }),
});

export default combineReducers({
    notification: notificationReducer,
    messageNotification: messageNotificationReducer
});
