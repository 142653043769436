import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Text } from "../../../containers/Language";
import useWindowDimension from "../../../hooks/useWindowDimension";
import { showToast } from "../../../state/ducks/utils/operations";
import HookForm from "../../../components/HookForm/HookForm";
import CheckBox from "../../../components/UI/CheckBox/CheckBox";
import Label from "../../../components/UI/Label/Label";
import TextField from "../../../components/UI/TextField/TextField";
import CustomCheckButton from "../../../components/UI/CustomCheckButton/CustomCheckButton";
import { get } from "lodash";
import { login, initializeSession } from "../../../state/ducks/auth/operations";
import { Link } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import Constant from "../../../util/constant";
import StorageService from "../../../services/localstorage.service";

import "./Login.scss";

const loginForm = {
    email: {
        name: "email",
        validate: {
            required: {
                value: true,
                message: "EMAIL_ERROR_VALIDATE",
            },
            pattern: {
                value: Constant.REGEX.EMAIL,
                message: "EMAIL_VALID",
            },
        },
    },
    password: {
        name: "password",
        validate: {
            required: {
                value: true,
                message: "PASSWORD_EMPTY",
            },
        },
    },
};

function AdminLogin(props) {
    const dimensions = useWindowDimension();

    const [form, setLoginForm] = useState();
    const [showPass, setShowPass] = useState(false);
    const [rememberMe, setRememberMe] = useState(
        StorageService.getItem("admin_ubikate_remember_me") || false
    );
    const [busy, setBusy] = useState(false);
    const onKeyDown = (e) => {
        // e.keyCode === 13 && onFormSubmit();
    };
    useEffect(() => {
        let timer = setInterval(() => {
            if (window.drift && window.drift.api) {
                window.drift.api.widget.hide();
            }
        }, 3500);
        setTimeout(() => {
            console.log(timer);
            clearInterval(timer)
        }, 30000);
    }, [])
    const onFormSubmit = async (data) => {
        try {
            let param = {
                email: data.email,
                password: data.password,
                role: 1,
            };
            setBusy(true);
            let response = await props.login(param);
            const { message, payload } = response;
            const { user, tokens } = payload;
            setBusy(true);
            props.initializeSession({ user, tokens });
            if (rememberMe) {
                StorageService.setItem("admin_ubikate_email", data.email);
                StorageService.setItem("admin_ubikate_password", data.password);
            } else {
                StorageService.removeItem("admin_ubikate_email");
                StorageService.removeItem("admin_ubikate_password");
            }
            StorageService.setItem("admin_ubikate_remember_me", rememberMe);

            // let lan = StorageService.getItem('rcml-lang')
            // if (lan === 'es') {
            //   props.showToast({
            //     message: "Conectado con éxito.",
            //     type: "success"
            //   });
            // } else if (lan === 'pt') {
            //   props.showToast({
            //     message: "Conectado com sucesso.",
            //     type: "success"
            //   });
            // } else {
            props.showToast({
                message: "Logged in successfully.",
                type: "success",
            });
            // }
            setTimeout(() => {
                // setBusy(false)
                props.history.push("/admin/dashboard");
            }, 1000);
        } catch (err) {
            setBusy(false);
            props.showToast({
                message: get(err, "response.data.message", "Something went wrong!"),
                type: "error",
            });
        }
    };

    return (
        <div>
            <Scrollbars
                className="loginAdminPage"
                style={{ height: dimensions.height + "px" }}
            >
                <div className="authcenter">
                    <div className="authfull">
                        <div className="authleftwidth">
                            <Label title="Let’s get connected" className="findtitle"></Label>
                            <Label
                                title="Fill in your details to log in!"
                                className="yourlogin"
                            ></Label>
                            <HookForm
                                defaultForm={{
                                    email: StorageService.getItem("admin_ubikate_email"),
                                    password: StorageService.getItem("admin_ubikate_password")
                                }}
                                init={(form) => {
                                    setLoginForm(form);
                                    StorageService.getItem("admin_ubikate_email") && form.trigger();
                                }}
                                onSubmit={onFormSubmit}
                            >
                                {(formMethod) => {
                                    return (
                                        <div className="form">
                                            <TextField
                                                formMethod={formMethod}
                                                rules={loginForm.email.validate}
                                                name={loginForm.email.name}
                                                errors={formMethod?.errors}
                                                autoFocus={true}
                                                type="text"
                                                iconClass="icon-at"
                                                placeholder="EMAIL_PLACEHOLDER"
                                            />
                                            <TextField
                                                formMethod={formMethod}
                                                rules={loginForm.password.validate}
                                                name={loginForm.password.name}
                                                errors={formMethod?.errors}
                                                type={showPass ? "text" : "password"}
                                                placeholder="PASSWORD_PLACEHOLDER"
                                                iconClass={showPass ? "icon-eye-on" : "icon-eye-off"}
                                                onKeyDown={onKeyDown}
                                                onIconClick={() => setShowPass(!showPass)}
                                            />

                                            <div className="checkforgot">
                                                <CheckBox
                                                    id="remember"
                                                    label_title="LABEL_TITLE"
                                                    checked={rememberMe}
                                                    onCheckedChange={(checked) => setRememberMe(checked)}
                                                ></CheckBox>
                                                <div className="forgotlink">
                                                    <Link to="/admin/forgotpassword">
                                                        <Text tid="FORGOT_PASSWORD" />
                                                    </Link>
                                                </div>
                                            </div>

                                            {/* <div className="lanbox">
                          <p><Text tid="PREFERRED_LAN" /></p>
                          <LanguageSelector />
                        </div> */}

                                            <div className="submitbtn">
                                                <CustomCheckButton
                                                    type="submit"
                                                    btnicon="check"
                                                    disabled={!formMethod?.formState.isValid}
                                                    loading={busy}
                                                />
                                            </div>
                                        </div>
                                    );
                                }}
                            </HookForm>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    showToast,
    login,
    initializeSession,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdminLogin)
);
