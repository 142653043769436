import { get } from "lodash";
import { combineReducers } from "redux";
import * as types from "./types";

import { createReducer } from "../../utils";
const profileReducer = createReducer(null)({
    [types.LATINO_PROFILE_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', {})
    },
});
const get_all_Latino_Reducer = createReducer([])({

    [types.GET_ALL_LATINO_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', [])
    },
    [types.SORT_LATINO_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', [])
    },

});
const get_near_Latino_Reducer = createReducer([])({

    [types.GET_NEAR_LATINO_COMPLETED]: (state, action) => {
        // console.log("Action ===>", action)
        if (action.payload.data) {
            return get(action, 'payload.data.payload', [])
        } else {
            return get(action, 'payload.payload', [])
        }
    },

});
export default combineReducers({
    profile: profileReducer,
    all_latino: get_all_Latino_Reducer,
    near_latinos: get_near_Latino_Reducer
});
