const safeJSONParser = json => {
  try {
    return JSON.parse(json);
  } catch (err) {
    return json;
  }
};

export const getItem = key => {
  return safeJSONParser(window.localStorage.getItem(key));
};

export const setItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = key => {
  window.localStorage.removeItem(key);
};

export const clear = () => {
  window.localStorage.clear();
};
// eslint-disable-next-line
export default { getItem, setItem, removeItem, clear };
