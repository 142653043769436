import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "../../pages/Admin/Login";
import { AdminLayoutRoute } from "./../../routes";

// import Header from '../Admin/Header/Header';

class AdminLayout extends React.Component {
  baseRoute = {
    path: "/admin",
    component: Login,
  };
  loggedInUserBaseRoute = "/admin/dashboard";
  isLoggedIn() {
    let user_id = localStorage.getItem("user");
    let token = localStorage.getItem("access_token");
    if (!user_id || !token) return false;
    else return true;
  }

  authenticateRoute(props) {
    let { path } = props.match;
    let user = JSON.parse(localStorage.getItem("user"));
    let isLoggedIn = this.isLoggedIn();
    let routeIndex = AdminLayoutRoute.findIndex((route) => route.path === path);
    console.log("landing the page from admin");
    if(user.role === 1 && path.startsWith("/admin")){
    if (path === "/admin" && isLoggedIn) {
      return <Redirect to={this.loggedInUserBaseRoute} />;
    } else if (path !== "/admin" && routeIndex !== -1) {
      const Component = AdminLayoutRoute[routeIndex].component;
      return isLoggedIn ? <Component {...props} /> : <Redirect to="/admin" />;
    } else {
      const Component = this.baseRoute.component;
      return <Component {...props} />;
    }
  }
  else{
    return <Redirect to="/admin/*" />;
  } 
}
 

  render() {
    return (
      <div className="main-page">
        {/* <Header {...this.props} /> */}
        <Switch>
          {AdminLayoutRoute.map((route, index) => {
            return route.authenticate ? (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                render={this.authenticateRoute.bind(this)}
              />
            ) : (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
);
