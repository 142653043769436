import React from 'react';
import { Toast } from 'react-bootstrap';
import WebService from "../../../config/webService";
import { get } from "lodash";
import { connect } from "react-redux";
import { showToast } from "../../../state/ducks/utils/operations";

import { showLoader, hideLoader } from "../../../state/ducks/utils/operations";
import './ToastComponent.scss';

const ToastComponent = (props) => {
  // console.log(props);
  const { message, type, show, timer, onClose, isButton } = props;
  //let iconClass, toastClass;
  let toastClass, btnicon;
  if (type === 'success') {
    toastClass = 'success-txt';
    btnicon = "success";
  } else if (type === 'error') {
    toastClass = 'error-txt';
    btnicon = "error";
  } else if (type === 'warning') {
    toastClass = 'warning-txt';
  }
  const onResendMail = async () => {
    try {
      let data = JSON.parse(localStorage.getItem("email"));
      console.log(data.email);
      onClose()
      props.showLoader();
      let lan = localStorage.getItem('rcml-lang');
      const param = {
        email: data.email,
        language: lan ? lan : 'en'
      };
      const response = await WebService.post("/auth/send-mail", param);
      if (lan === 'es') {
        props.showToast({
          message: "Su enlace de verificación se ha enviado correctamente",
          type: "success"
        });
      } else if (lan === 'pt') {
        props.showToast({
          message: "Seu link de verificação foi enviado com sucesso",
          type: "success"
        });
      } else {
        props.showToast({
          message: "Your verification link has been sent successfully",
          type: "success",
        });
      }
      props.hideLoader();
    } catch (err) {
      props.hideLoader();
      props.showToast({
        message: get(err, "response.data.message", "Error occured"),
        type: "error",
      });
    }
  }
  return (
    <Toast onClose={() => onClose && onClose()} className={toastClass} show={show} delay={timer || 3000} autohide>
      <div className="tostcenter">
        <i className={'icon-' + btnicon}></i>
        <p>{message}

          {localStorage.getItem('rcml-lang') === 'es' && isButton && <p><br /> No ha recibido correo electrónico. Haga clic en el botón <a href="javascript::void(0)" style={{ background: "#fba819", padding: '4px', borderRadius: '4px 9px'}} onClick={onResendMail}>Reenviar</a> y se enviará un nuevo enlace de verificación a su correo electrónico.</p>}
          {localStorage.getItem('rcml-lang') === 'pt' && isButton && <p><br />Você não recebeu e-mail. Clique no botão <a href="javascript::void(0)" style={{ background: "#fba819", padding: '4px', borderRadius: '4px 9px' }} onClick={onResendMail}>Reenviar</a> e um novo link de verificação será enviado para o seu e-mail.</p>}
          {(localStorage.getItem('rcml-lang') === 'en' || localStorage.getItem('rcml-lang') === null) && isButton && <p><br /> You have not received email. Please click on the <a href="javascript::void(0)" style={{ background: "#fba819", padding: '4px 9px', borderRadius: '5px' }} onClick={onResendMail}>Resend</a> button and a new verification link will be sent to your email.</p>}
        </p>

      </div>
    </Toast>
  )
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = {
  showToast,
  showLoader,
  hideLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastComponent);
// export default ToastComponent;
