import React from "react";
import { Button } from "react-bootstrap";
import Loader from "../../../assets/images/loadingGiphy.gif";

import "./CustomCheckButton.scss";

const CustomCheckButton = (props) => {
  let { title, disabled, type, btnicon, loading } = props;
  return (
    <Button
      className="customcheck"
      type={type}
      onKeyDown={props.onKeyDown}
      disabled={disabled}
      onClick={props.onClick}
    >
      {loading ? (
        <div className="btn-loader">
          <img src={Loader} alt="loader" height={40} width={40} />
        </div>
      ) : (
        <i className={"icon icon-" + btnicon}></i>
      )}
      {title}
    </Button>
  );
};

CustomCheckButton.defaultProps = {
  className: "customcheck",
};

export default CustomCheckButton;
