import React, { useEffect, useState } from "react";
// import { PubNubProvider, PubNubConsumer } from 'pubnub-react';
import Notification from "react-web-notification";

import App from "./App";
// import { pubnub, addListener, subscribe } from "./config/pubnub";
import {
  showNotificationDots,
  showMessageDots,
} from "./state/ducks/notification/operations";
import { connect } from "react-redux";
import { useSocket } from "./context/SocketContext";
import { socketEvents } from "./util/socketEvents";

function NotificationProvider(props) {
  const { socket } = useSocket();
  let [notification, setNotification] = useState({
    title: "",
    options: {},
  });

  const notificationOptions = (description) => {
    return {
      tag: Date.now(),
      body: description,
      lang: "en",
      dir: "ltr",
    };
  };

  useEffect(() => {
    if (!socket) return;

    socket.onAny(onNotificationReceived);
    return () => {
      socket.onAny(onNotificationReceived);
    };
  }, [socket]);

  const onNotificationReceived = (event, payload) => {
    if(!payload) return;

    
    if (event === socketEvents.MESSAGE_RECEIVED) {
      props.showMessageDots(true)
    } else if(event === socketEvents.NOTIFICATION) {
      props.showNotificationDots(true)
      const { title="", description="" } = payload;
      setNotification({
        title: title,
        options: notificationOptions(description),
      });
    }

  };

  return (
    <div>
      <Notification
        timeout={5000}
        title={notification.title}
        options={notification.options}
      />
      <App />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  showNotificationDots,
  showMessageDots,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationProvider);
