import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from '../../../containers/Language';

import './CheckBox.scss';

const CheckBox = (props) => {
    const languageContext = useContext(LanguageContext);
    let { label_title, id, onCheckedChange, register, name } = props;
    const [checked, setChecked] = useState(!!props.checked)

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    const onChecked = (e) => {
        setChecked(e.target.checked)
        onCheckedChange && onCheckedChange(e.target.checked)
    }

    return (
        <div className="checkboxMain">
            <input type="checkbox" ref={register} name={name} value={label_title} id={id} className='checkbox' checked={checked} onChange={onChecked} />
            {/* <label id="checkLabel" htmlFor={id}><span>{languageContext.dictionary[label_title] || label_title}</span></label> */}
            <label id="checkLabel" htmlFor={id}><span dangerouslySetInnerHTML={{ __html: languageContext.dictionary[label_title] || label_title }}></span></label>
        </div >
    )
}

CheckBox.defaultProps = {
    id: 1,
    checked: false,
    label_title: '',
}

export default CheckBox;