import { lazy } from 'react';
import EndUser from "./layout/EndUser//index";
import AdminLayout from "./layout/Admin/index";


const Landing = lazy(() => import(
  /* webpackChunkName: "Landing" */ /* webpackPreload: true */ './pages/Landing/Landing'
));
const Verify = lazy(() => import(
  /* webpackChunkName: "verify" */ /* webpackPreload: true */ './pages/Verify/Verify'
));
const Faqs = lazy(() => import(
  /* webpackChunkName: "faq" */ /* webpackPreload: true */ './pages/Faqs/Faqs'
));
const RulesOfEngagement = lazy(() => import(
  /* webpackChunkName: "rulesofengagement" */ /* webpackPreload: true */ './pages/RulesOfEngagement/RulesOfEngagement'
));
const Condition = lazy(() => import(
  /* webpackChunkName: "condition" */ /* webpackPreload: true */ './pages/Condition/Condition'
));
const PrivacyPolicy = lazy(() => import(
  /* webpackChunkName: "privacy" */ /* webpackPreload: true */ './pages/PrivacyPolicy/PrivacyPolicy'
));


const Login = lazy(() => import(
  /* webpackChunkName: "login" */ /* webpackPreload: true */ './pages/EndUser/Login'
));
const ForgotPassword = lazy(() => import(
  /* webpackChunkName: "forgotpassword" */ /* webpackPreload: true */ './pages/EndUser/ForgotPassword'
));
const ResetPassword = lazy(() => import(
  /* webpackChunkName: "resetpassword" */ /* webpackPreload: true */ './pages/EndUser/ResetPassword'
));
const SignUp = lazy(() => import(
  /* webpackChunkName: "signup" */ /* webpackPreload: true */ './pages/EndUser/SignUp'
));
const AdminLogin = lazy(() => import(
  /* webpackChunkName: "adminlogin" */ /* webpackPreload: true */ './pages/Admin/Login'
));
const Dashboard = lazy(() => import(
  /* webpackChunkName: "dashboard" */ /* webpackPreload: true */ './pages/EndUser/Dashboard/Dashboard'
));
const MyLatinos = lazy(() => import(
  /* webpackChunkName: "mylatinos" */ /* webpackPreload: true */ './pages/EndUser/MyLatinos'
));
const MyActivity = lazy(() => import(
  /* webpackChunkName: "myactivity" */ /* webpackPreload: true */ './pages/EndUser/MyActivity/MyActivity'
));
const Services = lazy(() => import(
  /* webpackChunkName: "services" */ /* webpackPreload: true */ './pages/EndUser/Services'
));
const Job = lazy(() => import(
  /* webpackChunkName: "job" */ /* webpackPreload: true */ './pages/EndUser/Job'
));
const ProfileChat = lazy(() => import(
  /* webpackChunkName: "profilechat" */ /* webpackPreload: true */ './pages/EndUser/ProfileChat/ProfileChat'
));
const AdminForgotPassword = lazy(() => import(
  /* webpackChunkName: "adminforgotpassword" */ /* webpackPreload: true */ './pages/Admin/ForgotPassword'
));
const AdminResetPassword = lazy(() => import(
  /* webpackChunkName: "adminresetpassword" */ /* webpackPreload: true */ './pages/Admin/ResetPassword'
));



const AdminDashboard = lazy(() => import(
  /* webpackChunkName: "AdminDashboard" */ /* webpackPreload: true */ './pages/Admin/Dashboard/Dashboard'
));
const AdminLatinoProfile = lazy(() => import(
  /* webpackChunkName: "AdminLatinoProfile" */ /* webpackPreload: true */ './pages/Admin/LatinoProfile/LatinoProfile'
));
const AdminProfile = lazy(() => import(
  /* webpackChunkName: "AdminProfile" */ /* webpackPreload: true */ './pages/Admin/MyProfile/MyProfile'
));
const AdminChangePassword = lazy(() => import(
  /* webpackChunkName: "AdminChangePassword" */ /* webpackPreload: true */ './pages/Admin/ChangePassword/ChangePassword'
));
const ChangePassword = lazy(() => import(
  /* webpackChunkName: "ChangePassword" */ /* webpackPreload: true */ './pages/EndUser/ChangePassword'
));
const ProfileEdit = lazy(() => import(
  /* webpackChunkName: "ProfileEdit" */ /* webpackPreload: true */ './pages/EndUser/ProfileEdit'
));
const PageNotFound = lazy(() => import(
  /* webpackChunkName: "PageNotFound" */ /* webpackPreload: true */ './pages/NotFound/NotFound'
));




// import Landing from "./pages/Landing/Landing";
// import Verify from "./pages/Verify/Verify";
// import Faqs from "./pages/Faqs/Faqs";
// import RulesOfEngagement from "./pages/RulesOfEngagement/RulesOfEngagement";

// import Login from "./pages/EndUser/Login";
// import ForgotPassword from "./pages/EndUser/ForgotPassword";
// import ResetPassword from "./pages/EndUser/ResetPassword";
// import SignUp from "./pages/EndUser/SignUp";
// import AdminLogin from "./pages/Admin/Login";

// import Dashboard from "./pages/EndUser/Dashboard/Dashboard";
// import MyLatinos from "./pages/EndUser/MyLatinos";
// import MyActivity from "./pages/EndUser/MyActivity/MyActivity";
// import ProfileChat from "./pages/EndUser/ProfileChat/ProfileChat";
// import AdminForgotPassword from "./pages/Admin/ForgotPassword";
// import AdminResetPassword from "./pages/Admin/ResetPassword";
// import AdminDashboard from "./pages/Admin/Dashboard/Dashboard";
// import AdminLatinoProfile from "./pages/Admin/LatinoProfile/LatinoProfile";
// import AdminProfile from "./pages/Admin/MyProfile/MyProfile";
// import AdminChangePassword from "./pages/Admin/ChangePassword/ChangePassword";
// import ChangePassword from "./pages/EndUser/ChangePassword";
// import ProfileEdit from "./pages/EndUser/ProfileEdit";
// import PageNotFound from "./pages/NotFound/NotFound";

const routes = [
  {
    path: "/admin",
    component: AdminLayout,
    authenticate: false,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    authenticate: false,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
    exact: true,
    authenticate: false,
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
    exact: true,
    authenticate: false,
  },
  {
    path: "/signup",
    component: SignUp,
    exact: true,
    authenticate: false,
  },
  {
    path: "/",
    component: EndUser,
    authenticate: false,
  },
  {
    path: "/test",
    component: PageNotFound,
    exact: true,
    authenticate: false,
  },
];

export const EndUserRoute = [
  {
    path: "/",
    component: SignUp,
    exact: true,
    authenticate: false,
  },
  {
    path: "/landing",
    component: SignUp,
    exact: true,
    authenticate: false,
  },
  {
    path: "/verify",
    component: Verify,
    exact: true,
    authenticate: false,
  },
  {
    path: "/faqs",
    component: Faqs,
    exact: true,
    authenticate: false,
  },
  {
    path: "/rulesofengagement",
    component: RulesOfEngagement,
    exact: true,
    authenticate: false,
  },
  {
    path: "/terms&condition",
    component: Condition,
    exact: true,
    authenticate: false,
  },
  {
    path: "/privacypolicy",
    component: PrivacyPolicy,
    exact: true,
    authenticate: false,
  },
  {
    path: "/",
    component: Login,
    exact: true,
    authenticate: false,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
    authenticate: true,
  },
  {
    path: "/mylatinos",
    component: MyLatinos,
    exact: true,
    authenticate: true,
  },
  {
    path: "/myactivity",
    component: MyActivity,
    exact: true,
    authenticate: false,
  },
  {
    path: "/services",
    component: Services,
    exact: true,
    authenticate: false,
  },
  {
    path: "/job",
    component: Job,
    exact: true,
    authenticate: false,
  },
  {
    path: "/profilechat",
    component: ProfileChat,
    exact: true,
    authenticate: true,
  },
  {
    path: "/profileedit",
    component: ProfileEdit,
    exact: true,
    authenticate: true,
  },
  {
    path: "/changepassword",
    component: ChangePassword,
    exact: true,
    authenticate: true,
  },
  {
    path: "/*",
    component: PageNotFound,
    exact: true,
    authenticate: false,
  },
];

export const AdminLayoutRoute = [
  {
    path: "/admin",
    component: AdminLogin,
    exact: true,
    authenticate: false,
  },
  {
    path: "/admin/login",
    component: AdminLogin,
    exact: true,
    authenticate: false,
  },
  {
    path: "/admin/forgotpassword",
    component: AdminForgotPassword,
    exact: true,
    authenticate: false,
  },
  {
    path: "/admin/resetpassword",
    component: AdminResetPassword,
    exact: true,
    authenticate: false,
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
    exact: true,
    authenticate: true,
  },
  {
    path: "/admin/latinoprofile/:id",
    component: AdminLatinoProfile,
    exact: true,
    authenticate: true,
  },
  {
    path: "/admin/profile",
    component: AdminProfile,
    exact: true,
    authenticate: true,
  },
  {
    path: "/admin/changepassword",
    component: AdminChangePassword,
    exact: true,
    authenticate: true,
  },
  {
    path: "/admin/*",
    component: PageNotFound,
    exact: true,
    authenticate: false,
  },
];

export default routes;
