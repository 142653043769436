import * as types from "./types";
import { getParams } from "../../../util/services"


export const showNotificationDots = (payload) => ({
    type: types.SHOW_NOTIFICATION_DOTS,
    payload
})
export const showMessageDots = (payload) => ({
    type: types.SHOW_MESSAGE_DOTS,
    payload
})
