
const Constant = {
    BASEURL: 'https://ubikate-api.herokuapp.com/v1',
    // BASEURL: 'https://api-ubikate-staging.herokuapp.com/v1',
    // BASEURL: 'http://192.168.1.238:4200/v1',
    REGEX: {

        EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,// eslint-disable-next-line
        SPECIALCHARACTERS: /[!@#\$%\^\&*\)\(+=._-]/g,// eslint-disable-next-line
        NUMBER: /[0-9]/g,// eslint-disable-next-line
        Name: /^[ a-zA-Z]*$/i,// eslint-disable-next-line
        ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,// eslint-disable-next-line
        LOWERCASEUPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,// eslint-disable-next-line
        Url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,// eslint-disable-next-line
        NUMERIC: /^\d*\.?\d*$/,// eslint-disable-next-line
        POSITIVENUMBERS: /^[1-9][0-9]*$/,
    },
    KEYS: {
        KeyToken: "access_token"
    },
    GOOGLEMAPKEY: {
        // ApiKey: "AIzaSyC2tK2itcy8Pfykl2X2rcyOpoJcBDl-5pU"
        ApiKey: "AIzaSyBgDpg68RE9Ql46hb53ZtoHtOFbKShCXhU" //Matthew
        // ApiKey: "AIzaSyA2RQLFGtMqFP8LtZBq-94NPSopLf0Ypuk" //
    },
    PUBNUB: {
        publishKey: 'pub-c-9b5499fc-a1bc-4a25-b041-680112faeb5c',
        subscribeKey: 'sub-c-f8320b70-a97c-11eb-b2e3-7226f347561f',
    },
    IMAGE: {
        Profile: 'https://res.cloudinary.com/zudu/image/upload/v1617352902/UBIKate/blank-profile.png'
    }
}

export default Constant