import * as types from "./types";

export const login = (payload) => ({
  type: types.LOGIN,
  meta: {
    async: true,
    blocking: true,
    path: "/auth/login",
    method: "POST",
    body: payload,
  },
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const authenticated = () => ({
  type: types.AUTHENTICATED,
});

export const initializeSession = (payload) => ({
  type: types.INITIALIZE,
  payload,
});

export const destroySession = () => ({
  type: types.DESTROY,
});

export const refreshToken = () => ({
  type: types.REFRESH_TOKEN,
});

export const forgotPassword = (payload) => ({
  type: types.FORGOT_PASSWORD,
  meta: {
    async: true,
    blocking: true,
    path: "auth/forgot-password",
    method: "POST",
    body: payload,
  },
});

export const resetPassword = (payload, token) => ({
  type: types.RESET_PASSWORD,
  meta: {
    async: true,
    blocking: true,
    path: `/auth/reset-password/?token=${token}`,
    method: "POST",
    body: payload,
  },
});

export const verifyResetPasswordLink = (token) => ({
  type: types.VERIFY_RESET_PASSWORD_LINK,
  meta: {
    async: true,
    blocking: true,
    path: `/auth/checkResetLink/?token=${token}`,
    method: "GET",
  },
});
export const changePassword = (userId, params) => ({
  type: types.CHANGE_PASSWORD,
  meta: {
    async: true,
    blocking: true,
    path: `/user/${userId}/change-password`,
    method: "PATCH",
    body: params,
  },
});
