import * as types from "./types";

export const getLatinoProfileDetails = (id) => ({
    type: types.LATINO_PROFILE,
    meta: {
        async: true,
        blocking: true,
        path: `user/${id}`,
        method: "GET"
    },
});
export const getAllLatino = (params) => ({
    type: types.GET_ALL_LATINO,
    meta: {
        async: true,
        blocking: true,
        path: `/user/?search=${params.search}&limit=${params.limit}&page=${params.page}`,
        method: "GET"
    },
});
export const filterLatino = (params) => (
    {
        type: types.SORT_LATINO,
        meta: {
            async: true,
            blocking: true,
            path: `/user/?search=${params.search}&limit=${params.limit}&page=${params.page}&sortBy=${params.sortby}`,
            method: "GET"
        }
    }
);
export const getNearbyLatino = (params) => (
    {
        type: types.GET_NEAR_LATINO,
        meta: {
            async: true,
            blocking: true,
            path: `/latinos?miles=${params.miles}&lat=${params.lat || ''}&long=${params.lng || ''}&country=${params.country || ''}`,
            method: "GET"
        }
    }
);
export const editLatinoProfileDetails = (id, params) => ({
    type: types.LATINO_EDIT_PROFILE,
    meta: {
        async: true,
        blocking: true,
        path: `user/${id}`,
        method: "PATCH",
        body: params,
    },
});