import en from './en.json';
import es from './es.json';
import pt from './pt.json';

export const dictionaryList = { en, es, pt };

export const languageOptions = {
    en: 'English',
    es: 'Spanish',
    pt: 'Portuguese'
};