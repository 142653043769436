import React, { createContext, useContext, useEffect, useState } from "react";
import socketio from "socket.io-client";
import config from "../util/constant";

// Function to establish a socket connection with authorization token
const getSocket = (token) => {
  // If token doesn't exist, return null
  if (!token) return null;

  return socketio(config.BASEURL.replace('/v1', ""), {
    auth: { token },
  });
};

const SocketContext = createContext({
  socket: null,
  initiateConnection: () => {},
  destroyConnection: () => {},
  connected: false,
});

const useSocket = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);

  // Function to initiate a connection with the provided token
  const initiateConnection = () => {
    destroyConnection();

    const token = localStorage.getItem("access_token");
    const newSocket = getSocket(token);
    setSocket(newSocket);

    if (!newSocket) return;

    newSocket.on("connect", () => {
      setConnected(true);
    });

    newSocket.on("reconnect", () => {
      setConnected(true);
    });

    newSocket.on("disconnect", (reason) => {
      setConnected(false);
      if (reason === "io server disconnect") {
        destroyConnection();
      }
    });

    newSocket.on("connect_error", (error) => {
      setConnected(false);
      // console.error("Connection error:", error);
    });

    newSocket?.on("connect_timeout", () => {
      setConnected(false);
      console.error("Connection timed out");
    });
  };

  const destroyConnection = () => {
    if (socket) {
      socket.close();
      setSocket(null);
    }
  };

  useEffect(() => {
    initiateConnection();

    // Clean-up function to disconnect the socket when the component unmounts
    return () => {
      destroyConnection();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        connected,
        initiateConnection,
        destroyConnection,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { SocketProvider, useSocket };
