import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import './AuthTextField.scss';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import InputMask from "react-input-mask";
import { LanguageContext } from '../../../containers/Language';

const TextField = (props) => {

  const languageContext = useContext(LanguageContext);

  let { errors, placeholder, multiErrorFields, disabled, type, textarea, name, autoFocus, handleFocus, handleBlur, iconClass,
    onIconClick, onKeyDown, maskType, noTextfield, formMethod, rows, defaultValue, rules } = props

  let values = formMethod?.getValues()
  let hasError = errors[name] !== undefined;

  return (
    <div className="authTextField">
      <Form.Group controlId="formBasicEmail" className={hasError ? 'error' : ''}>
        <>
          {noTextfield ?
            <Controller
              className="form-control"
              as={<InputMask />}
              mask={maskType}
              maskChar=""
              control={formMethod?.control}
              name={name}
              rules={rules}
              defaultValue={defaultValue}
              placeholder={placeholder}
              disabled={disabled}
            />
            :
            <Controller
              defaultValue={defaultValue}
              render={({ onChange }) =>
                <Form.Control className={hasError ? 'error' : ''}
                  name={name}
                  autoFocus={autoFocus}
                  disabled={disabled}
                  autoComplete="off"
                  type={type}
                  as={textarea}
                  rows={rows}
                  onBlur={(e) => handleBlur && handleBlur(e)}
                  onKeyDown={onKeyDown}
                  onFocus={(e) => { handleFocus && handleFocus(e) }}
                  onChange={(e) => {
                    e.target.value = e.target.value.trimLeft()
                    onChange && onChange(e.target.value.trimLeft())
                    props.onChange && props.onChange(e)
                  }}
                  placeholder={languageContext.dictionary[placeholder] || placeholder}
                  value={formMethod.watch(name)}
                />
              }
              name={name}
              control={formMethod?.control}
              rules={rules}
            />
          }
          {disabled ?
            <i className={"field-icon-disabled " + iconClass} onClick={() => values[name] && values[name] !== '' && !disabled && onIconClick()}></i> :
            <i className={"field-icon " + iconClass} onClick={() => values[name] && values[name] !== '' && !disabled && onIconClick()}></i>
          }

          {multiErrorFields.length > 0 ?
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ messages }) => {
                if (messages) {
                  let isMultipleError = Object.keys(messages).every((errKey) => multiErrorFields.filter((m) => m[errKey] !== undefined).length > 0)
                  if (isMultipleError) {
                    let arr = []
                    for (const fieldError of multiErrorFields) {
                      let key = Object.keys(fieldError)[0]
                      let success = Object.keys(messages).includes(key)
                      arr.push(
                        <div className={success ? 'red' : 'green'} >
                          <span key={key}>{languageContext.dictionary[fieldError[key]] || fieldError[key]}</span>
                        </div>
                      )
                    }
                    return <div className="errorMsg show passwordcustom">{arr}</div>
                  } else {
                    return <div className='errorMsg show'><p><i className='icon-warning'></i>{languageContext.dictionary[errors[name]?.message] || errors[name]?.message}</p></div>
                  }
                } else {
                  return <div className='errorMsg'></div>
                }
              }}
            /> : (hasError ? <div className='errorMsg show'><p><i className='icon-warning'></i>{languageContext.dictionary[errors[name]?.message] || errors[name]?.message}</p></div> : <div className='errorMsg'>error</div>)}
        </>
      </Form.Group>
    </div>
  );
}

TextField.defaultProps = {
  autoFocus: false,
  value: '',
  errors: {},
  multiErrorFields: []
}

export default TextField;
