import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import routes from "./routes";
import { hideToast } from "./state/ducks/utils/operations"

// import Loader from "./components/Loading/Loading"
import 'bootstrap/dist/css/bootstrap.min.css';
import ToastComponent from "./components/UI/ToastComponent"
import CookieConsent from "react-cookie-consent";
import { Text } from "./containers/Language";
import Loader from "./assets/images/giphy.gif";

function App(props) {
  // const { toast, hideToast, loader } = props;
  const { toast, hideToast } = props;

  return (
    <div className="App" >
      <Suspense fallback={<h1></h1>}>

        {
          props.loader &&
          <div className="loader">
            <img src={Loader} alt="loader" height={80} width={80} />
          </div>
        }
        <ToastComponent show={toast.show} message={toast.message} type={toast.type} timer={toast.timer} onClose={hideToast} isButton={toast.isButton} />
        {
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText={<i className="icon icon-close"></i>}
            cookieName="myAwesomeCookieName2"
            style={{ background: "#fba819" }}
            buttonStyle={{ background: '#1b3766', color: '#ffffff', fontSize: "14px", borderRadius: 25, fontFamily: 'montserratmedium', width: 'auto', height: 'auto', padding: '5px 15px 7px 15px', margin: '5px 15px 6px 15px' }}
            enableDeclineButton
            flipButtons
            declineButtonStyle={{ background: 'transparent', color: '#ffffff', fontSize: "14px", borderRadius: 25, fontFamily: 'montserratmedium', width: 'auto', height: 'auto', padding: '5px 15px 7px 15px', margin: '5px 10px 6px 0' }}
          >
            <Text tid="This website uses cookies to enhance the user experience." />
            {/* <button className="cookie-close"><i className="icon icon-close"></i></button> */}
          </CookieConsent>
        }
        <BrowserRouter>
          <Switch>
            {routes.map((route, index) => {
              return <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            })}
          </Switch>
        </BrowserRouter>
      </Suspense>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    toast: state.utils.toast,
    loader: state.utils.loader
  }
};

const mapDispatchToProps = {
  hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
