export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const GOOGLE_MAP_LOAD = "GOOGLE_MAP_LOAD";

export const CURRENT_COUNTRY = "CURRENT_COUNTRY";

// -----------------SERVICES--------------------

export const ADD_SERVICES = "ADD_SERVICES";

export const GET_SERVICES = "GET_SERVICES";
export const GET_SERVICES_COMPLETED = "GET_SERVICES_COMPLETED";

export const UPDATE_SERVICES = "UPDATE_SERVICES";

export const DELETE_SERVICES = "DELETE_SERVICES"

// -----------------JOBS------------------------

export const ADD_JOBS = "ADD_JOBS";

export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_COMPLETED = "GET_JOBS_COMPLETED";

export const UPDATE_JOBS = "UPDATE_JOBS";

export const DELETE_JOBS = "DELETE_JOBS";

