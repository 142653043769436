import axios from 'axios';
import Constants from '../util/constant';
import { get } from "lodash";
import JwtService from "./../services/jwt.service";
export default class WebService {
    static WebRequest = {
        BASE_URL: Constants.BASEURL,
        // BASE_URL: "http://localhost:4000/v1/",

        API: {
            //Login & Signup Process
            login: 'user/login',
            forgotPasssword: 'user/forgotPassword',
            resetPassword: 'user/resetPassword',
            checkResetPasswordLink: 'user/checkResetPasswordLink',
            checkVerifyLink: 'user/verifyUser',
            resendVerifyLink: 'user/resendSignupVerification',
            firstTimeLogin: 'user/firstLogin',
            signUp: 'user/registerUser',
            completeProfile: 'user/completeProfile', //temp
            addBusinessUBO: 'user/addBusinessUBO',
            getAdminAndEmployee: 'user/getAdminAndEmployee',
            changePassword: 'user/changePassword',
            checkRegisteredClient: 'user/checkRegisteredClient',

            //Dashboard
            adminEmployeeDashboard: 'dashboard/getDashboard',
            propertyManagerDashboard: 'dashboard/getPropertyManagerDashboard',
            getAllTransactions: 'dashboard/getAllTransactions',

            //User
            getUser: 'user/getUser',
            getUserProfile: 'user/getUserProfile',
            updateProfile: 'user/editProfileBusiness',
            updateProfileIndividual: 'user/editProfileIndividual',
            addUser: 'user/addUser',
            editUser: 'user/editUser',
            deleteUser: 'user/deleteUsers',
            enableDisableUser: 'user/enableDisableUsers',
            enableDisableMediator: 'user/enableDisableMediator',
            getMyBuilders: 'user/getMyBuilders',
            getMyClients: 'user/getMyClients',
            getSubscriptionPlan: 'user/getSubscriptionPlan',
            addSubscriptionPlan: 'user/addSubscriptionPlan',

            // add TradesPeople 
            addTradesPeople: 'user/createTradesPeople',
            editTradesPeople: "user/editTradesPerople",
            //jobs
            getAllJobs: 'jobs/getAllJobs',
            getJobDetails: 'jobs/getJobDetails',
            createJob: 'jobs/createJob',
            editJob: 'jobs/editJob',
            getModificationRequest: 'modificationRequest/getModificationRequest',
            addChat: 'jobs/addChat',
            uploadImage: 'jobs/uploadImages',
            cancelJobAndRefund: 'jobs/cancelJobAndRefund',
            deleteJob: 'jobs/deleteJob',

            //milestone
            announceDecision: '/disputeMilestone/announceDecision',

            //workProgress
            getWorkProgressIssues: 'workProgress/getWorkProgressIssues',

            //comments
            getRejectionComments: 'rejectComments/getAllRejectCommentRequest',

            //demo
            getCompaniesHouseApi: 'user/getCompaniesHouseApi',

            //notification
            getNotificationList: 'allNotification/getAllNotification',
            deleteNotification: 'allNotification/deleteNotification',
            addNotification: 'allNotification/addNotification',
            markOneAsRead: 'allNotification/markOneAsRead',

            //TrueLayer
            paymentStatus: 'trueLayer/status',

            //Referral Code 
            refferralCode: "user/referral/list",
            voucherSent: "user/referral/voucherSent"

        },
    }

    static async post(action, params) {
        let response = await axios.post(this.WebRequest.BASE_URL + action, params)
        return response.data
    }
    static async put(action, params) {
        let response = await axios.put(this.WebRequest.BASE_URL + action, params)
        return response.data
    }
    static async get(action, params) {
        let response = await axios.get(this.WebRequest.BASE_URL + action, params)
        return response.data
    }
    static async delete(action, params) {
        let response = await axios.delete(this.WebRequest.BASE_URL + action, params)
        return response.data
    }
    static async patch(action, params) {
        let response = await axios.patch(this.WebRequest.BASE_URL + action, params)
        return response.data
    }
}

axios.interceptors.request.use(
    async (config) => {
        // Do something before request is sent
        config.baseURL = Constants.BASEURL;
        const token = await window.localStorage.getItem("access_token");
        config.headers.common["Authorization"] = token ? token : "";
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

let isRefreshing = false;
let failedQueue = [];

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    (error) => {
        console.log(error);

        // // Any status codes that falls outside the range of 2xx cause this function to trigger
        // // Do something with response error
        const { config, response } = error;
        const originalRequest = config;

        if (response.status === 404 && config.url.indexOf("refresh-tokens") != -1) {
            localStorage.clear();
            window.location.href = "/";
            return Promise.reject(error);
        }

        if (response.status === 401 && config.url.indexOf("refresh-tokens") != -1) {
            processQueue(error, null);
            isRefreshing = false;
            originalRequest._retry = false;
            return Promise.reject(error);
        } else if (response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers["Authorization"] = token;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(async (resolve, reject) => {
                const refreshToken = await window.localStorage.getItem("refresh_token");
                const request = { refreshToken };
                axios
                    .post(`${Constants.BASEURL}/auth/refresh-tokens`, request)
                    .then(async (response) => {
                        const token = get(response.data, "payload.tokens.access.token", "");
                        const refreshToken = get(
                            response.data,
                            "payload.tokens.refresh.token",
                            ""
                        );
                        await window.localStorage.setItem("access_token", token);
                        await window.localStorage.setItem("refresh_token", refreshToken);
                        axios.defaults.headers.common["Authorization"] = token;
                        originalRequest.headers["Authorization"] = token;
                        processQueue(null, response.data.access_token);
                        resolve(axios(originalRequest));
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        } else {
            return Promise.reject(error);
        }
    }
);

const processQueue = (error, token = null) => {
    failedQueue.forEach((promise) => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });

    failedQueue = [];
};