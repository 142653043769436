import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./AuthHeader.scss";
import { Link } from "react-router-dom";
import { logout } from "../../state/ducks/auth/operations";
import {
  showNotificationDots,
  showMessageDots,
} from "../../state/ducks/notification/operations";

import logo from "../../assets/images/Somos-3.png";

import LandingLanguageSelector from "../../components/LandingLanguageSelector/LandingLanguageSelector";
import { Text } from "../../containers/Language";

const AuthHeader = (props) => {
  return (
    <div className="header_container container">
      <header className="header d-flex justify-content-between">
        <div className="logoContainer">
          <Link to="/">
            <img
              src={
                "https://res.cloudinary.com/dllrhzmuu/image/upload/v1723198225/somoslations%20assets/homepage/Somos-1-Hrzntl-high_qiko7y.png"
              }
              alt="logo"
              className="headerLogo"
            />
            {/* <img src={resplogo} alt="logo" className="respLogo" /> */}
          </Link>
        </div>
        <nav>
          <ul>
            <li>
              <LandingLanguageSelector />
            </li>
            <li>
              <Link to="/faqs">
                <Text tid="LANDING_FAQS" />
              </Link>
            </li>
            <li><a target="_blank" rel="noopener" href="https://blog.somoslatinos.co">BLOG</a></li>
          </ul>
        </nav>
      </header>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showDots: state.notification.notification,
    messageNotification: state.notification.messageNotification,
  };
};

const mapDispatchToProps = {
  logout,
  showNotificationDots,
  showMessageDots,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthHeader)
);
