import React, { useContext, useState } from "react";
import { LanguageContext } from "../../containers/Language";
import "./LandingLanguageSelector.scss";

const languages = [
  { label: "PRTGS", id: "pt" },
  { label: "ESP", id: "es" },
  { label: "ENG", id: "en" },
];

function LandingLanguageSelector() {
  const [clickedId, setClickedId] = useState(
    localStorage.getItem("rcml-lang") ? localStorage.getItem("rcml-lang") : "en"
  );

  const { userLanguageChange } = useContext(LanguageContext);

  function handleLanguageChange(event, id) {
    setClickedId(id);
    userLanguageChange(id);
  }

  return (
    <div className="btn_container">
      {languages.map((l, idx) => {
        return (
          <>
            {idx != 0 && <div className="divider">|</div>}
            <button
              onClick={(e) => handleLanguageChange(e, l.id)}
              className={`btn ${l.id === clickedId ? "active" : ""}`}
            >
              {l.label}
            </button>
          </>
        );
      })}
    </div>
  );
}

export default LandingLanguageSelector;
