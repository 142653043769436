import * as types from "./types";

export const showToast = (payload) => ({
    type: types.SHOW_TOAST,
    payload
});

export const hideToast = (payload) => ({
    type: types.HIDE_TOAST,
});

export const showLoader = (payload) => ({
    type: types.SHOW_LOADER,
    payload
});

export const hideLoader = (payload) => ({
    type: types.HIDE_LOADER,
});
export const mapLoad = (payload) => ({
    type: types.GOOGLE_MAP_LOAD,
});
export const currentCountry = (payload) => ({
    type: types.CURRENT_COUNTRY,
    payload
});

// --------------------------SERVICES-----------------------------

export const getServices = (params) => {
    return (
        {
            type: types.GET_SERVICES,
            meta: {
                async: true,
                blocking: true,
                path: `/service`,
                method: "GET"
            }
        })
}

export const addServices = (payload) => ({
    type: types.ADD_SERVICES,
    meta: {
        async: true,
        blocking: true,
        path: `/service/createservice`,
        method: "POST",
        body: payload
    }
})

export const updateServices = (payload, serviceId) => ({
    type: types.UPDATE_SERVICES,
    meta: {
        async: true,
        blocking: true,
        path: `/service/${serviceId}`,
        method: "PATCH",
        body: payload
    }
})

export const deleteServices = (id) => ({
    type: types.DELETE_SERVICES,
    meta: {
        async: true,
        blocking: true,
        path: `/service/delete/${id}`,
        method: "DELETE",
        // body: payload
    }
})

// -----------------------------JOBS--------------------------------

export const getJob = (params) => {
    return (
        {
            type: types.GET_JOBS,
            meta: {
                async: true,
                blocking: true,
                path: `/job`,
                method: "GET"
            }
        })
}

export const addJob = (payload) => ({
    type: types.ADD_JOBS,
    meta: {
        async: true,
        blocking: true,
        path: `/job/createjob`,
        method: "POST",
        body: payload
    }
})

export const updateJob = (payload, jobId) => ({
    type: types.UPDATE_JOBS,
    meta: {
        async: true,
        blocking: true,
        path: `/job/${jobId}`,
        method: "PATCH",
        body: payload
    }
})

export const deleteJob = (id) => ({
    type: types.DELETE_JOBS,
    meta: {
        async: true,
        blocking: true,
        path: `/job/delete/${id}`,
        method: "DELETE",
        // body: payload
    }
})