import React, { useContext } from "react";
import { LanguageContext } from '../../../containers/Language';

import './Label.scss';

const Label = (props) => {
    const languageContext = useContext(LanguageContext);
    let { title } = props;

    return (
        <h1 className={'titleText ' + props.className}>{languageContext.dictionary[title] || title}</h1>
    )
}

Label.defaultProps = {
    className: 'titleText',
}
export default Label;