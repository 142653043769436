import axios from "axios";
import { get } from "lodash";
import Constant from "../util/constant";
import JwtService from "./../services/jwt.service";

const instance = axios.create({
  baseURL: Constant.BASEURL,
  // baseURL: "http://localhost:4000/v1/",
});
let isRefreshing = false;
let failedQueue = [];

// Process all failed request
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) prom.reject(error);
    else prom.resolve(token);
  });
  failedQueue = [];
};

instance.interceptors.request.use((config) => {
  if (JwtService.getToken())
    config.headers["Authorization"] = JwtService.getToken();
  return config;
});

instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const originalRequest = error.config;
    const responseStatus = get(error, "response.status", "");

    // If refresh token fails
    if (
      responseStatus === 401 &&
      error.config.url.indexOf("refresh-token") != -1
    ) {
      processQueue(error, null);
      isRefreshing = false;
      return Promise.reject(error);
    }

    if (responseStatus === 401 && error.config.url.indexOf("login") != -1) {
      return Promise.reject(error);
    }

    // Check if original request
    if (responseStatus === 401 && !originalRequest._retry) {
      // Push all the failed request due to expired token in queue
      if (isRefreshing) {
        return new Promise((resolve, reject) =>
          failedQueue.push({ resolve, reject })
        )
          .then((token) => {
            originalRequest.headers["Authorization"] = "Token " + token;
            return instance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      // Try to refresh token
      return new Promise(async (resolve, reject) => {
        const refreshToken = await window.localStorage.getItem("refresh_token");
        const request = { refreshToken };
        axios
          .post(`${Constant.BASEURL}/auth/refresh-tokens`, request)
          .then(async (response) => {
            const token = get(response.data, "payload.tokens.access.token", "");
            const refreshToken = get(
              response.data,
              "payload.tokens.refresh.token",
              ""
            );
            await window.localStorage.setItem("access_token", token);
            await window.localStorage.setItem("refresh_token", refreshToken);
            axios.defaults.headers.common["Authorization"] = token;
            originalRequest.headers["Authorization"] = token;
            processQueue(null, response.data.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else {
      return Promise.reject(error);
    }
  }
);

/**
 * Set the default HTTP request headers
 */
const setHeader = () => {
  instance.defaults.headers.common[
    "Authorization"
  ] = `Token ${JwtService.getToken()}`;
};

export default instance;
