

export const LATINO_PROFILE = "LATINO_PROFILE";
export const LATINO_PROFILE_FAILED = "LATINO_PROFILE_FAILED";
export const LATINO_PROFILE_COMPLETED = "LATINO_PROFILE_COMPLETED";

export const LATINO_EDIT_PROFILE = "LATINO_EDIT_PROFILE";
export const LATINO_EDIT_PROFILE_FAILED = "LATINO_EDIT_PROFILE_FAILED";
export const LATINO_EDIT_PROFILE_COMPLETED = "LATINO_EDIT_PROFILE_COMPLETED";

export const GET_ALL_LATINO = "GET_ALL_LATINO";
export const GET_ALL_LATINO_FAILED = "GET_ALL_LATINO_FAILED";
export const GET_ALL_LATINO_COMPLETED = "GET_ALL_LATINO_COMPLETED";

export const SORT_LATINO = "SORT_LATINO";
export const SORT_LATINO_FAILED = "SORT_LATINO _FAILED";
export const SORT_LATINO_COMPLETED = "SORT_LATINO_COMPLETED";

export const GET_NEAR_LATINO = "GET_NEAR_LATINO";
export const GET_NEAR_LATINO_FAILED = "GET_NEAR_LATINO_FAILED";
export const GET_NEAR_LATINO_COMPLETED = "GET_NEAR_LATINO_COMPLETED";