import { combineReducers } from "redux";
import { get } from "lodash";
import * as types from "./types";

import { createReducer } from "../../utils";

const toastInitialState = {
    show: false,
    message: '',
    type: ''
}
const Country = {
    country: ''
}

const toastReducer = createReducer(toastInitialState)({
    [types.SHOW_TOAST]: (state, action) => ({ show: true, ...action.payload }),
    [types.HIDE_TOAST]: () => toastInitialState,
});

const loaderReducer = createReducer(false)({
    [types.SHOW_LOADER]: () => true,
    [types.HIDE_LOADER]: () => false,
});
const maploadReducer = createReducer(false)({
    [types.GOOGLE_MAP_LOAD]: () => true,
});
const currentCountryReducer = createReducer(Country)({
    [types.CURRENT_COUNTRY]: (state, action) => ({ country: action.payload }),
});
const servicesListReducer = createReducer([])({
    [types.GET_SERVICES_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', [])
    }
});
const jobListReducer = createReducer([])({
    [types.GET_JOBS_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload', [])
    }
});

export default combineReducers({
    toast: toastReducer,
    loader: loaderReducer,
    mapload: maploadReducer,
    currentCountry: currentCountryReducer,
    servicesList: servicesListReducer,
    jobList: jobListReducer,
});
